import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap:ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

const mobileBreakpoint = 768;

class Vergers2024 extends PewComponent {
  init() {

    //Init animations on dom ready
    $(document).ready(() => {
      this.initAnimations();
    });

  }

  initAnimations() {
    // create
    let mm = gsap.matchMedia();

    mm.add(
      {
        // set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
        isDesktop: `(min-width: ${mobileBreakpoint}px)`,
        isMobile: `(max-width: ${mobileBreakpoint - 1}px)`,
      },
      (context) => {
        let {isDesktop, isMobile, reduceMotion} = context.conditions;
        if (isDesktop) {
          const $horinzontalSections = this.element.find('.horizontal-scroll-container');
          $horinzontalSections.each((i, section) => {
            this.registerHorizontalScroll($(section));
          });

          this.registerNavigation(this.element.find('.vergers-nav.hide-medium'));
        } else if (isMobile) {
          this.registerNavigation(this.element.find('.vergers-nav.mobile-only'));
        }
      }
    );

    //Check if we have an anchor in the url
    if (window.location.hash) {
      //Scroll to the anchor
      setTimeout(() => {
        this.scrollToAnchor(window.location.hash);
      }, 200);
    }
  }

  getHeaderHeight() {
    return this.element.find('.nav-wrap .wp-block-group__inner-container').outerHeight();
  }

  registerHorizontalScroll($section) {
    const
      $panelsContainer = $section.parent(),
      panels = gsap.utils.toArray($section.find(">*")),
      isRtl = $section.css('direction') === 'rtl';

    let $slider = $section.find('.content-block .wdf-slider-carousel-mobile-only');
    if (!$slider.length) {
      $slider = $section.find('.content-block .wdf-slider-carousel-mobile-only');
    }

    const introWidth = $section.find('.intro-block').width(),
      contentWidth = $section.find('.content-block').width(),
      sliderWidth = $slider.width() || 0,
      headerWidth = $('#header').width(),
      safetyMargin = 50;

    const thisGap = parseInt($section.css('gap').replace('px', '')),
      paddingDirection = 'padding-right',
      thisPaddingStarts = parseInt($section.css(paddingDirection).replace('px', ''));

    let wrapperWidth = introWidth + thisGap + contentWidth + (sliderWidth - contentWidth);

    $panelsContainer.addClass('horizontal-scroll-parent');
    wrapperWidth += (thisGap * (panels.length - 1));
    $section.css('width', wrapperWidth + 'px');

    let displacement = wrapperWidth + thisPaddingStarts + headerWidth + thisGap + safetyMargin;
    if (isRtl) {
      displacement += headerWidth + safetyMargin;
    }
    displacement -= window.innerWidth;

    /*console.table({
    'introWidth': introWidth,
    'contentWidth': contentWidth,
    'sliderWidth': sliderWidth,
    'diff': sliderWidth - contentWidth,
    'wrapperWidth': wrapperWidth,
    'thisGap': thisGap,
    'thisPaddingStarts': thisPaddingStarts,
    'window.innerWidth': window.innerWidth,
    'displacement': displacement
    });*/

    if (displacement > 200) {
      const tween = gsap.to($section, {
        x: (isRtl ? displacement : -displacement) + "px",
        ease: "none",
        scrollTrigger: {
          trigger: $section.parents('.section'),
          //markers: true,
          pin: true,
          start: "top " + this.getHeaderHeight() + "px",
          scrub: 1,
          end: () => {
            let wrapperWidth = 0;
            panels.forEach((panel) => {
              wrapperWidth += panel.offsetWidth;
            });
            wrapperWidth += ($section.css('gap').replace('px', '') * (panels.length - 1));
            return "+=" + $section.height() + 'px';
          }
        }
      });
    }
  }

  registerNavigation($nav) {
    this.registerNavigationSticky($nav);
    this.registerScrollProgress($nav);
    this.registerNavigationProgress($nav);
  }

  registerNavigationSticky($nav) {
    ScrollTrigger.create({
      trigger: $nav.parent(),
      start: 'top top',
      pin: true,
      endTrigger: this.element,
      end: 'bottom top-=100',
      toggleClass: 'sticky-active',
      pinSpacing: false
    });
  }

  registerScrollProgress($nav) {
    //Remove any existing progress bar if it exists
    this.element.find('.progress-bar').remove();
    //create the progress bar next to the nav
    const progressBar = $('<div class="progress-bar"></div>');
    $nav.after(progressBar);
    //register scroll trigger to update progress bar
    ScrollTrigger.create({
      trigger: $nav,
      start: 'top top',
      endTrigger: this.element.find('#section-last-scroll'),
      end: 'top ' + this.getHeaderHeight() + 'px',
      //markers: true,
      onUpdate: (animationData) => {
        this.updateProgressBar(animationData, progressBar);
      },
      invalidateOnRefresh: true // Refresh the trigger on window resize or DOM changes
    });
  }

  updateProgressBar(animationData, progressBar) {
    gsap.to(progressBar, {
      width: animationData.progress * 100 + '%',
      duration: 0.2,
      ease: 'power2.out'
    });
  }

  registerNavigationProgress($nav) {
    const $navItems = $nav.find('a');
    const headerHeight = this.getHeaderHeight() + 2;

    $navItems.each((i, navItem) => {
      const $navItem = $(navItem);
      const $target = $($navItem.attr('href'));

      ScrollTrigger.create({
        trigger: $target,
        start: 'top ' + headerHeight + 'px',
        end: 'bottom ' + headerHeight + 'px',
        //markers: true,
        onEnter: () => {
          $navItem.parent().addClass('active');
        },
        onEnterBack: () => {
          $navItem.parent().addClass('active');
        },
        onLeave: () => {
          $nav.find('li').removeClass('active');
        },
        onLeaveBack: () => {
          $nav.find('li').removeClass('active');
        },
        invalidateOnRefresh: true // Refresh the trigger on window resize or DOM changes
      });
    });
  }

  scrollToAnchor(hash) {
    const $target = $(hash);
    if ($target.length) {
      console.log('scroll to anchor', hash, $target, $target.offset().top - this.getHeaderHeight());
      this.scrollTo($target.offset().top - this.getHeaderHeight(), 10);
    }

  }

  scrollTo(topPos, duration) {
    duration = duration || 750;
    if (window.smoothScrollMargin) {
      topPos -= window.smoothScrollMargin;
    }
    $('html,body').stop().animate({
      scrollTop: topPos
    }, duration);
  }
}

window.pew.addRegistryEntry({
  key: 'vergers2024',
  domSelector: 'body.page-vergers-abeilles-2024 div.type-page',
  classDef: Vergers2024
});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";

class CollapseTextComponent extends PewComponent {

  init() {

    const breakpoint = 768;

    this.element.find('.collapse-text-button').hide();

    if ($(window).width() < breakpoint) {
      this.initClickListener();
    }

    $(window).resize(() => {
      if ($(window).width() < breakpoint) {
        if (!this.$listenerElement) {
          this.initClickListener();
        }
      } else {
        if (this.$listenerElement) {
          this.resetListener();
        }
      }
    })
  }

  initClickListener() {
    this.element.find('.collapse-text-button').show();
    this.element.addClass('is-closed');

    this.$listenerElement = this.element.find('.collapse-text-button').click(() => {
      if (this.element.hasClass('is-opened')) {
        this.close();
      } else {
        this.open();
      }
    });
  }

  open() {
    this.element.addClass('is-opened');
    this.element.removeClass('is-closed');
    this.element.find('[aria-expanded]').attr('aria-expanded', true);
  }

  close() {
    this.element.removeClass('is-opened');
    this.element.addClass('is-closed');
    this.element.find('[aria-expanded]').attr('aria-expanded', false);
  }

  resetListener() {
    this.$listenerElement.off('click');
    this.$listenerElement = null;
    this.element.removeClass('is-opened');
    this.element.removeClass('is-closed');
    this.element.find('.collapse-text-button').hide();
  }
}

window.pew.addRegistryEntry({key: 'collapse-text', domSelector: '.collapse-text', classDef: CollapseTextComponent});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export default class ReadmoreToggle extends PewComponent {

  init() {
    const $link = this.element.find('.readmore-toggle');
    if (!$link.attr('data-toggle-expand')) {
      $link.attr('data-toggle-expand', $link.html().replace('<', '&lt;').replace('>', '&gt;'));
    }
    if (!$link.attr('data-toggle-reduce')) {
      $link.attr('data-toggle-reduce', 'Réduire');
    }
    this.initToggle();
  }

  initToggle() {
    this.element.find('.readmore-toggle').on('click', (e) => {
      e.preventDefault();
      let $link = $(e.currentTarget);
      let $desc = this.element.find('.readmore-target');
      if ($desc.css('display') === 'none') {
        this.open($link, $desc);
      } else {
        this.close($link, $desc);
      }
    });
  }

  open($link, $desc, cb) {
    $desc.slideDown(400, () => {
      $desc.attr('aria-hidden', false);
      $desc.focus();
      //$link.attr('aria-label',$link.attr('aria-label').replace('(cliquer pour ouvrir)','(cliquer pour fermer)'));
      $link.attr('aria-expanded', true);
      $link.html($link.attr('data-toggle-reduce').replace('&lt;', '<').replace('&gt;', '>'));
      cb && cb($link, $desc);
    });
    $link.addClass('open');
  }

  close($link, $desc, cb) {
    $desc.slideUp(400, () => {
      $desc.attr('aria-hidden', true);
      //$link.attr('aria-label',$link.attr('aria-label').replace('(cliquer pour fermer)','(cliquer pour ouvrir)'));
      $link.attr('aria-expanded', false);
      $link.html($link.attr('data-toggle-expand').replace('&lt;', '<').replace('&gt;', '>'));
      cb && cb($link, $desc);
    });
    $link.removeClass('open');
  }
}

window.pew.addRegistryEntry({key: 'readmore-toggle', domSelector: '.readmore-context', classDef: ReadmoreToggle});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";

const typeahead = require('TypeAhead');
const Bloodhound = require('BloodHound');

export default class RecipePickerComponent extends PewComponent {
  init() {
    this.registerRecipePickerToggle();
  }

  registerRecipePickerToggle() {
    /*const i18n = (wonderwp && wonderwp.i18n) ? wonderwp.i18n : null;
    let opts = {
      search_contains: true
    };
    if (i18n && i18n['chosen.nomatch.trad']) {
      opts.no_results_text = i18n['chosen.nomatch.trad']
    }
    const $recipeSelect = this.element.find('.recipe-select');
    opts.placeholder_text_single = $recipeSelect.data('placeholder');
    $recipeSelect.chosen(opts);
    $recipeSelect.on('change', (e, chosenData) => {
      if (chosenData.selected) {
        this.redirectToRecipe(chosenData.selected)
      }
    });*/

    const $searchInput = this.element.find('.recipe-picker-input');
    const dataSet = this.loadDataSet();
    const $typeAheadInstance = $searchInput.typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    }, dataSet);
    $typeAheadInstance.bind('typeahead:select', (ev, suggestion) => {
      this.redirectToRecipe(suggestion.slug);
    });

  }

  loadDataSet() {
    const recipesData = $.parseJSON(this.element.find('.recipes-data').html());

    const recipesEngine = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: recipesData
    });
    return {
      name: 'recipes',
      source: recipesEngine,
      display: "title"
    }
  }

  redirectToRecipe(slug) {
    const loader = '<span class="loader"></span>';
    this.element.append(loader);
    //console.log('redirect to ', slug);
    window.location.href = '/recette/' + slug;
  }

}

window.pew.addRegistryEntry({key: "recipe-picker", domSelector: ".recipe-picker-component", classDef: RecipePickerComponent});

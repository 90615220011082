import { PewComponent } from '../../../assets/raw/js/components/pew-component';
import gsap from 'gsap';

export default class Engagements2024Collapse extends PewComponent {

  constructor(element, options) {
    super(element, options);
  }

  get isMobile() {
    return $(window).width() < 768;
  }

  init() {
    // Initial animation data
    this.masterTl = gsap.timeline();
    this.collapseSpeed = .4;
    this.hasCollapse = false

    // Collapse elements
    this.$els = $('.js-engagement-collapse');
    this.$headers = $('.js-engagement-collapse-header');
    this.$contents = $('.js-engagement-collapse-content');

    // Breakpoint Collapse Activation
    this.isActive = false;
    this.initBreakpointListener();

    this.$headers.on('click keydown', (e) => {
      if (
        this.masterTl.isActive() ||
        !this.isMobile ||
        (e.type === 'keydown' && !['Enter', 'Space'].includes(e.originalEvent.code))
      ) {
        return;
      }
      e.preventDefault();
      this.toggleCollapse(e.currentTarget);
    })
  }


  /**
   * Init animation for gsap
   * - Hide content for collapse animation
   */
  initAnimation() {
    if (this.isActive === this.isMobile || !this.isMobile) {
      return;
    }

    this.$contents.each((index, el) => {
      gsap.set(el, {
        height: 0,
        display: 'none',
      });
    })
  }

  initBreakpointListener() {
    $(window).on('resize', () => {
      this.initAnimation();
      this.updateAccessibilityAttributes();
      this.isActive = this.isMobile;
    });

    this.initAnimation();
    this.updateAccessibilityAttributes();
    this.isActive = this.isMobile;
  }

  updateAccessibilityAttributes() {
    if (this.isActive === this.isMobile) {
      return;
    }

    if (this.isMobile) {
     this.setAccessibility();
    } else {
      this.removeAccessibility();
    }
  }

  toggleCollapse(header) {
    // Reset animation data
    this.masterTl.clear();
    this.hasCollapse = false;

    // Get current collapse elements
    const $currentHeader = $(header);
    const $currentEl = $currentHeader.closest('.js-engagement-collapse');
    const $currentContent = $currentEl.find('.js-engagement-collapse-content');
    const expand = $currentHeader.attr('aria-expanded') === 'false';

    // Close all open engagements collapse element
    this.$els.each((index, el) => {
      const $el = $(el);
      const isExpanded = $el.hasClass('is-expanded');
      if ($currentEl === $el || !isExpanded) {
        return;
      }

      const $header = $el.find('.js-engagement-collapse-header');
      const $content = $el.find('.js-engagement-collapse-content');
      $el.removeClass('is-expanded');
      $header.attr('aria-expanded', 'false');

      this.masterTl.add(this.collapseAnim($content, false), '<');
    })

    $currentEl.toggleClass('is-expanded', expand);
    /**
     * Collapse content directly if no collapse or wait for collapse animation
     */
    this.masterTl.add(
      this.collapseAnim($currentContent, expand)
        .eventCallback(
          'onStart',
          () => {
            $currentHeader.attr('aria-expanded', expand ? 'true' : 'false')
          }
        )
        .eventCallback(
          'onComplete',
          () => {
            if (!expand)
              return
            // Scroll to the current accordion
            const offset = $currentHeader.offset()
            window.scrollTo({
              left: offset.left,
              top: offset.top - (50 + 78 / 2),
              behavior: 'smooth'
            })
          }
        ),
      this.hasCollapse ? '<' : '+=0'
    );
  }

  /**
   * Add accessibility attributes
   * to the header and the content on mobile
   * - Make the header div act like a button (role, tabbable, sync with content, expand status)
   * - Add an id to the content
   */
  setAccessibility() {
    this.$headers.attr('role', 'button');
    this.$headers.attr('tabindex', '0');
    this.$headers.attr('aria-expanded', 'false');

    this.$els.each((index, el) => {
      const $el = $(el);
      const $header = $el.find('.js-engagement-collapse-header');
      const $content = $el.find('.js-engagement-collapse-content');
      const elementId = $el.attr('id');
      const contentId = elementId + '-content';

      $header.attr('aria-controls', contentId);
      $content.attr('id', contentId);
    })
  }

  /**
   * Remove accessibility attributes
   * because there are not needed on desktop
   */
  removeAccessibility() {
    this.$headers.removeAttr('role aria-controls tabindex aria-expanded');
    this.$contents.removeAttr('id style');
  }

  collapseAnim($content, expand) {
    this.hasCollapse = true
    const content = $content.get(0);
    if (!expand) {
      return gsap.to(
        content,
        {
          height: 0,
          display: 'none',
          duration: this.collapseSpeed,
          ease: 'power3',
        }
      );
    } else {
      content.style.display = ''
      return gsap.to(
        content,
        {
          height: 'auto',
          duration: this.collapseSpeed,
          ease: 'power3',
        }
      );
    }
  }
}

window.pew.addRegistryEntry({
  key: 'engagements2024Collapse',
  domSelector: '.page-engagements-2024',
  classDef: Engagements2024Collapse,
});

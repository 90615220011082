import {Menu} from "./Menu";

const cbDelay = 0;

export default class AccordionMenu extends Menu {

  startOpen(cb) {
    let $children = this.element.find('> .children');

    this.ensureOnlyOneOpenedAtSameLevelOpened();

    if ($children.length) {
      $children.slideDown(400, () => {
        setTimeout(() => {
          cb && cb();
        }, cbDelay);
      });
    } else {
      cb && cb();
    }
  }

  startClose(cb) {
    let $children = this.element.find('> .children');
    if ($children.length) {
      this.element.find('> .children').slideUp(400, () => {
        setTimeout(() => {
          cb && cb();
        }, cbDelay);
      });
    } else {
      cb && cb();
    }
  }

  ensureOnlyOneOpenedAtSameLevelOpened() {
    let $siblings = this.element.siblings();
    $.each($siblings, (i, sibling) => {
      let $sibling = $(sibling),
        menu = $sibling.data('menu');
      if (menu) {
        menu.close();
      }
    })
  }
}

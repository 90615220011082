import {AccordionComponent} from "../../Accordion/AccordionComponent";


export const DefaultAccordionOptions = {
  defaultSelector: '.recipe-etapes-content'
}

export class RecipeDetailEtapesAccordion extends AccordionComponent {
  init() {
    super.init();
    this.breakpoint = 768;
    this.initBreakpointListener();
    this.initExpandAllButton();
  }

  initBreakpointListener() {
    $(window).resize(() => {
      this.checkBreakpoint();
    });

    this.checkBreakpoint();
  }

  checkBreakpoint() {
    if ($(window).width() < this.breakpoint) {
      if (!this.dom.hasClass('is-mobile')) {
        this.collapseAll();
        this.dom.addClass('is-mobile');
      }
    } else {
      if (this.dom.hasClass('is-mobile')) {
        this.expandAll();
        this.dom.removeClass('is-mobile');
      }
    }
  }

  initExpandAllButton() {
    $('.recipe-etapes-expand-all-button').click((e) => {
      const $element = $(event.currentTarget);
      if ($element.hasClass('expanded')) {
        this.collapseAll();
        $element.removeClass('expanded');
        $element.addClass('collapsed');
      } else {
        this.expandAll();
        $element.removeClass('collapsed');
        $element.addClass('expanded');
      }
    });
  }

  expandAll() {
    $('.recipe-etapes-expand-all-button')
      .removeClass('collapsed')
      .addClass('expanded');
    $('.js-accordion__header').each((index, element) => {
      this.openTab($(element));
    })
  }

  collapseAll() {
    $('.recipe-etapes-expand-all-button')
      .removeClass('expanded')
      .addClass('collapsed');
    $('.js-accordion__header').each((index, element) => {
      this.closeTab($(element));
    })
  }

  closeTab($accordionHeader) {
    const $this_panel = $("#" + $accordionHeader.attr("aria-controls")),
      $accordion = $accordionHeader.parent(),
      accordion_multiselectable = $accordion.attr("aria-multiselectable") === 'true',
      $all_accordion_headers = $accordion.find(".js-accordion__header"),
      $all_accordion_panels = $accordion.find(".js-accordion__panel");

    $accordionHeader.attr("aria-expanded", "false");
    $this_panel.attr("aria-hidden", "true");

    if (!accordion_multiselectable) {
      $all_accordion_panels.not($this_panel).attr("aria-hidden", "true");
      $all_accordion_headers.not($accordionHeader).attr("aria-expanded", "false");
    }
  }

  openTab($accordionHeader) {
    var $this_panel = $("#" + $accordionHeader.attr("aria-controls")),
      $accordion = $accordionHeader.parent(),
      accordion_multiselectable = $accordion.attr("aria-multiselectable") === 'true',
      $all_accordion_headers = $accordion.find(".js-accordion__header"),
      $all_accordion_panels = $accordion.find(".js-accordion__panel");

    $accordionHeader.attr("aria-expanded", "true");
    $this_panel.attr("aria-hidden", "false");

    if (!accordion_multiselectable) {
      $all_accordion_panels.not($this_panel).attr("aria-hidden", "true");
      $all_accordion_headers.not($accordionHeader).attr("aria-expanded", "false");
    }
  }
}


window.pew.addRegistryEntry({
  key: 'recipe-etapes-accordion',
  domSelector: DefaultAccordionOptions.defaultSelector,
  classDef: RecipeDetailEtapesAccordion
});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export default class LangSwitcherComponent extends PewComponent {
  init() {
    this.registerLangSwitcherToggle();
  }

  registerLangSwitcherToggle() {
    //Lang switcher
    this.element.find('button').off('click.langswitch');
    this.element.find('button').on('click.langswitch', (e) => {
      e.preventDefault();
      this.element.find(".langswitcher-choices").toggleClass("open");
    });
  }
}

window.pew.addRegistryEntry({key: "lang-switcher", domSelector: ".langswitcher", classDef: LangSwitcherComponent});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export default class FooterComponent extends PewComponent {
  init() {
    this.registerAccordion();
  }

  registerAccordion() {
    this.element.find('.col ul').attr('role', 'menu');
    this.element.find('.footer-title').on('click', (e) => {
      e.preventDefault();
      if (window.innerWidth < 700) {
        const $btn = $(e.currentTarget),
          $col = $btn.parent(),
          transitionOpts = {
            duration: 300,
            easing: 'linear'
          };

        $col.find('> ul').slideToggle(transitionOpts);
        $col.siblings().not('.col-social').find('> ul').slideUp(transitionOpts);

        const $submenuItems = $col.find('ul a');
        $submenuItems.attr('role', 'button');
      }
    });
  }
}

window.pew.addRegistryEntry({key: 'footer', domSelector: '#colophon', classDef: FooterComponent});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export class RecipeFilterComponent extends PewComponent {

  init() {
    this.isRadio = $('.recipes-filter').find('.radio-group.suggestions-wrap').length > 0;
    this.updateState(true);
    this.initListeners();
  }

  updateState(initial = false) {
    const $items = this.element.find('.recipe-filter-item-component');

    $items.each((index, element) => {
      const $element = $(element);
      const id = this.getIdFrom($element);
      const title = $element.find('.recipe-filter-title').text();

      $element.attr('aria-label', title);

      let checked;
      if (this.isRadio) {
        checked = $('.recipes-filter').find(`#suggestions__${id}`).prop('checked');
      } else {
        checked = $('.recipes-filter').find(`[name="suggestions[${id}]"]`).prop('checked');
      }

      if (checked) {
        $element.addClass('active');
        $element.parent().addClass('active-parent');
        this.element[0].scrollLeft = 0;
        $element.attr('aria-selected', 'true');
      } else {
        $element.removeClass('active');
        $element.parent().removeClass('active-parent');
        $element.attr('aria-selected', 'false');
      }

      if (!initial) {
        $('.recette-filters-form').submit();
      }
    });
  }


  initListeners() {
    this.element.find('.recipe-filter-item-component').keydown((e) => {
      if (e.keyCode === 13) {
        $(e.currentTarget).click();
      }
    });

    this.element.find('.recipe-filter-item-component').click((e) => {
      const $element = $(e.currentTarget);
      const id = this.getIdFrom($element);

      let $input;
      if (this.isRadio) {
        $input = $('.recipes-filter').find(`#suggestions__${id}`);
      } else {
        $input = $('.recipes-filter').find(`[name="suggestions[${id}]"]`);
      }

      $input.prop('checked', !$input.prop('checked'));

      this.updateState();
    });
  }

  getIdFrom($element) {
    return $element.attr('id').replace('suggestion-', '');
  }
}

// Default
window.pew.addRegistryEntry({
  key: "recipe-suggestions",
  domSelector: ".recipe-suggestions",
  classDef: RecipeFilterComponent
});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export class MeaTogglerComponent extends PewComponent {
  init() {
    const $trigger = this.element;
    const $target = this.element.parent().find('.more-content');

    $trigger.on('click', (e) => {
      if ($target.attr('aria-hidden') === 'true') {
        $trigger.addClass('content-opened');
        $target.attr('aria-hidden', false);
      } else {
        $trigger.removeClass('content-opened');
        $target.attr('aria-hidden', true);
      }
    });
  }
}

// Default
window.pew.addRegistryEntry({
  key: "mea-toggler",
  domSelector: ".mea-component .more-trigger",
  classDef: MeaTogglerComponent
});

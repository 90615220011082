import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import NoAjaxTransitionComponent from "../NoAjaxTransition/NoAjaxTransitionComponent";

export class ScrollSpyComponent extends PewComponent {
  init() {
    this.initNav();
    this.registerScroll();
  }

  initNav() {
    const $sections = this.element.find('> .section');

    const $navbar = $('<nav class="scrollspy-nav"><ul></ul></nav>');

    $sections.each((index, element) => {
      const title = $(element).find('.scrollspy-title').text();
      const id = `scrollspy-section-${index}`;
      const activeClass = index === 0 ? 'active' : '';

      $(element).attr('id', id);

      $(`<li class="${activeClass}"><a href="#${id}" role="button">${title}</a></li>`).appendTo($navbar.find('ul'));
    });

    this.element.prepend($navbar);

    new NoAjaxTransitionComponent($(this.element));
  }

  registerScroll() {
    const $sections = this.element.find('> .section');
    const $firstSection = $sections.first();
    const navbarOffset = 30;
    const menuBreakpoint = 768;
    let isSticky = false;

    $(window).on('scroll resize orientationchange', (e) => {
      const scrollTop = $(window).scrollTop();
      let offset = window.smoothScrollMargin ? window.smoothScrollMargin: 10;
      offset += navbarOffset + 10;
      // Add a offset to be sure to match the element offset top
      const triggerPoint = (scrollTop + offset);

      $($sections.get().reverse()).each((index, element) => {
        if (triggerPoint >= $(element).offset().top) {
          const id = $(element).attr('id');
          const $li = $(`[href="#${id}"]`).closest('li');
          $li.addClass('active');
          $('.scrollspy-nav').find('li').not($li).removeClass('active');
          return false;
        }
      });

      const stickyTrigger = $(window).width() < menuBreakpoint ? scrollTop + navbarOffset + $('#header').outerHeight() : scrollTop + navbarOffset;

      if (stickyTrigger >= $firstSection.offset().top && !isSticky) {
        let left = $('#header').outerWidth();
        let top = 0;

        if ($(window).width() < menuBreakpoint) {
          left = 0;
          top = $('#header').outerHeight();
        }

        $('.scrollspy-nav').css({
          position: 'fixed',
          top: `${top}px`,
          left: `${left}px`,
          right: 0,
          transform: 'none'
        });

        isSticky = true;
      } else if (stickyTrigger < $firstSection.offset().top && isSticky) {
        $('.scrollspy-nav').removeAttr('style');
        isSticky = false
      }
    });
  }
}

// Default
window.pew.addRegistryEntry({key: "wdf-scrollspy", domSelector: ".scrollspy", classDef: ScrollSpyComponent});

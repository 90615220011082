import "modaal/dist/js/modaal";
import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

// https://github.com/humaan/Modaal

export class ModalComponent extends PewComponent {
  constructor(element, passedOptions) {

    let defaultOptions = {
      background: "#481423",
      overlay_opacity: .6,
      fullscreen: false,
      close_text: "Fermer"
    };

    //Recup / override des options par data attribut
    const dataSet = $(element).data();
    if (dataSet) {
      for (let i in dataSet) {
        if (i.includes("modaal")) {
          let optIndex = i.replace('modaal', '').toLowerCase();
          defaultOptions[optIndex] = dataSet[i];
        }
      }
    }

    defaultOptions['ajax_success'] = ($modal_wrapper) => {
      this.initLoadedContent($modal_wrapper);
    };

    if (defaultOptions['type'] && defaultOptions['type'] === 'inline') {
      defaultOptions.after_open = ($modal_wrapper) => {
        this.initLoadedContent($modal_wrapper);
      }
    }

    if (window && window.wonderwp && window.wonderwp.i18n && window.wonderwp.i18n['close.trad']) {
      defaultOptions.close_text = window.wonderwp.i18n['close.trad'];
    }
    defaultOptions['before_open'] = (e) => {
      this.$triggerLink = $(e.currentTarget);
    }
    defaultOptions['before_close'] = ($modal_wrapper) => {
      //stop audio
      // List of all audio tags
      const audios = $modal_wrapper.find('audio').toArray();
      //Iterate over all
      for (var i = 0; i < audios.length; i++) {
        var audio = audios[i];
        // is playing ?
        if (!audio.paused) {
          //Close audio
          audio.pause();
        }
      }
    }
    defaultOptions['after_close'] = (e) => {
      $(this.$triggerLink).blur();
    }

    if (defaultOptions['animation'] && defaultOptions['animation'] === 'immersive') {
      let observer;

      defaultOptions['before_open'] = (e) => {
        this.$triggerLink = $(e.currentTarget);
        // Selectionne le noeud dont les mutations seront observées
        const targetNode = $('body')[0];

        const config = {attributes: false, childList: true, subtree: true};

        // Fonction callback à éxécuter quand une mutation est observée
        const callback = (mutationsList) => {
          for (/** @type {MutationRecord} */const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
              const modalWrapper = [...mutation.addedNodes].find(node => $(node).hasClass('modaal-wrapper'));
              // L'observation peut être arrêtée par la suite
              observer.disconnect();

              if (modalWrapper) {
                const $modalWrapper = $(modalWrapper);
                const $modalContainer = $modalWrapper.find('.modaal-container'),
                  $engagementBox = $(e.currentTarget).parents('.scene'),
                  fromPos = this.calculatePosition($engagementBox[0]);

                $modalWrapper.addClass('modaal-immersive modaal-transitionning').removeClass('modaal-start_none');
                const headerWidth = $('#header').width();

                const customClass = $(e.currentTarget).data('modaal-custom_class');

                const isSmallOpening = customClass === 'small-opening' || customClass === 'small-opening--small-height';
                let modalWidth = isSmallOpening ? 450 : 900;
                let x = ($(window).width() - modalWidth - headerWidth) / 2;
                let modalHeight = isSmallOpening ? 450 : $(window).height() - 240;
                let y = isSmallOpening ? ($(window).height() - modalHeight) / 2 : 120;

                if ($(window).width() < 500) {
                  modalHeight = $(window).height() - 120;
                  y = 70
                  modalWidth = $(window).width() - 20;
                  x = ($(window).width() - modalWidth) / 2;
                } else if ($(window).width() < 768 && !isSmallOpening) {
                  modalHeight = $(window).height() - 140;
                  y = 70
                  modalWidth = isSmallOpening ? 450 : $(window).width();
                } else if ($(window).width() < 1000 && !isSmallOpening) {
                  modalHeight = 760;
                  y = ($(window).height() - modalHeight - headerWidth) / 2;
                  modalWidth = 720;
                  x = ($(window).width() - modalWidth - headerWidth) / 2;
                } else if ($(window).width() < 1320 && !isSmallOpening) {
                  modalHeight = 760;
                  y = ($(window).height() - modalHeight - headerWidth) / 2;
                }
                const elementY = $engagementBox.offset().top;
                const elementX = $engagementBox.offset().left;

                /** @type {TweenVars} */
                let fromVars = {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  y: elementY - $(window).scrollTop(),
                  x: elementX - headerWidth,
                  width: fromPos.width,
                  height: fromPos.height,
                  ease: "none",
                  backgroundColor: $engagementBox.find('.engagement-component').css('background-color')
                };

                /** @type {TweenVars} */
                let toVars = {
                  position: 'fixed',
                  duration: .5,
                  top: 0,
                  left: 0,
                  y: y,
                  x: x,
                  width: modalWidth,
                  height: modalHeight,
                  backgroundColor: '#ffffff',
                  onComplete: () => {
                    $modalWrapper.removeClass('modaal-transitionning');
                    $modalContainer.removeAttr('style');
                  }
                };

                gsap.set($modalContainer, fromVars);
                gsap.fromTo($modalContainer, fromVars, toVars);
              }
            }
          }
        };

        // Créé une instance de l'observateur lié à la fonction de callback
        observer = new MutationObserver(callback);

        // Commence à observer le noeud cible pour les mutations précédemment configurées
        observer.observe(targetNode, config);
      };

      defaultOptions['ajax_success'] = ($modalContentContainer) => {
        this.initLoadedContent($modalContentContainer);
      };
    }

    let opts = Object.assign(defaultOptions, passedOptions);

    super(element, opts);
  }

  init() {
    this.element.modaal(this.options);
  }

  initLoadedContent($modal_wrapper) {
    window.pew.enhanceRegistry($modal_wrapper[0]);
    $modal_wrapper.find('.transitionning').removeClass('transitionning');
  }

  calculatePosition(element) {
    const rect = element.getBoundingClientRect();

    const scrollTop = $(window).scrollTop();
    const scrollLeft = $(window).scrollLeft();

    return {
      top: Math.round(rect.top),
      left: Math.round(rect.left + scrollLeft),
      height: rect.height,
      width: rect.width,
    };
  }
}

// Default
window.pew.addRegistryEntry({key: "wdf-modal", domSelector: ".wdf-modal", classDef: ModalComponent});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {Menu} from "./Menu";
import AccordionMenu from "./AccordionMenu";

class headerComponent extends PewComponent {

  constructor(element, options) {
    let defaultOptions = {
      'classToToggleOnBody': 'has-opened-menu',
      'classToCheckIfHeaderShouldBeSticky': 'stickable',
      'classToToggleOnBodyWhenHeaderStuck': 'has-stuck-header',
      'stickyOffset': 'top'
    };
    super(element, Object.assign(defaultOptions, options));
    this.menu = new AccordionMenu(this.element.find('.navigations-wrapper'));
  }

  init() {
    this.registerMenuToggler();
    this.registerMenuCloseOnHoverOut();
    this.registerSticky();
  }

  registerMenuToggler() {
    let $toggler = $('[data-menu-toggler]');
    this.element.find('.children').attr('role', 'menu');
    this.element.find('li > a').attr('role', 'button');
    $toggler.on('click', (e) => {
      e.preventDefault();
      $toggler.toggleClass('is-active');
      $('body').toggleClass(this.options.classToToggleOnBody);
      this.menu.toggle();
    });
  }

  registerSticky() {
    if ($('body').hasClass(this.options.classToCheckIfHeaderShouldBeSticky)) {
      window.onscroll = function () {checkHeaderFixation()};
      checkHeaderFixation();

      const header = this.element[0];
      let stickTreshold = header.offsetTop;
      if (this.options.stickyOffset === 'top') {
        stickTreshold += 0;
      } else if (this.options.stickyOffset === 'center') {
        stickTreshold += this.element.outerHeight() / 2;
      } else if (this.options.stickyOffset === 'bottom') {
        stickTreshold += this.element.outerHeight();
      } else {
        stickTreshold += this.options.stickyOffset;
      }
      const classToToggleOnBodyWhenHeaderStuck = this.options.classToToggleOnBodyWhenHeaderStuck;

      function checkHeaderFixation() {
        let $body = $('body');
        if (window.pageYOffset > stickTreshold) {
          if (!$body.hasClass(classToToggleOnBodyWhenHeaderStuck)) {
            $body.addClass(classToToggleOnBodyWhenHeaderStuck);
          }
        } else {
          if ($body.hasClass(classToToggleOnBodyWhenHeaderStuck)) {
            $body.removeClass(classToToggleOnBodyWhenHeaderStuck);
          }
        }
      }

    }
  }

  registerMenuCloseOnHoverOut() {
    this.element.hover(
      () => {
        if (this.menuOutTimeout) {
          clearTimeout(this.menuOutTimeout);
        }
      },
      () => {
        this.menuOutTimeout = setTimeout(() => {
          if (window.innerWidth >= 768) {
            this.element.find('[data-menu-toggler]').removeClass('is-active');
            $('body').removeClass(this.options.classToToggleOnBody);
            this.menu.close();
          }
        }, 600);
      }
    );
  }

}

window.pew.addRegistryEntry({key: 'header', domSelector: '#header', classDef: headerComponent});

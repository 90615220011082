import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export default class NoAjaxTransitionComponent extends PewComponent {

  init() {
    this.element.removeClass('transitionning');
    this.setupTransitionTriggers();

    window.addEventListener('popstate', function(event) {
      $('#content').removeClass('transitionning');
    });
    window.addEventListener('pageshow', function(event) {
      if (event.persisted) {
        $('#content').removeClass('transitionning');
      }
    });

  }

  setupTransitionTriggers() {
    const exceptions = [
      '.no-transition',
      '.no-barba',
      '.module-actu .pagination a',
      '.module-faq .pagination a',
      '.post-edit-link',
      '.timeline-component a',
      '.wdf-modal',
      '.modaal',
      '.page_item_has_children > a',
      '.skip-links a',
      '.js-tablist__link'
    ];
    $('a').not(exceptions.join(',')).on('click', (e) => {
      let $link = $(e.currentTarget);
      let href = $link.attr('href');
      if (
        (
          href && (
            href.indexOf(window.location.host) !== -1 //on trouve bien l'url du site dans le lien
            || href.indexOf('?cat') !== -1 //Lien de cat
            || href.indexOf('./') !== -1 // Lien relatif
          )
        )
        && href.indexOf('#') === -1 //Pas une ancre
        && (!$(e.currentTarget).attr('target') || $link.attr('target') !== '_blank') //Pas un target blank
        && !e.metaKey //Meta key is not pressed
      ) {
        console.log('[Transition] lien concerne');
        $('#content').addClass('transitionning');
      } else {
        console.log('[Transition] lien non concerne');

        if (href && (href.indexOf('#') >= 0 && href !== '#')) {
          //this is an anchored link
          //Clean up anchor
          let selector = href.split('#')[1];
          //test with id
          let $target = $('#' + selector);
          if ($target.length === 0) {
            //Test witch class
            $target = $('.' + selector);
          }
          if ($target.length) {
            e.preventDefault();

            //Compute offset target
            //Is it specified on the link?
            let targetOffset = $link.data('target-offset');
            //Is it specified on the target
            if (!targetOffset) {
              targetOffset = $target.data('target-offset');
            }
            //Else, aim for top as default
            if (!targetOffset) {
              targetOffset = 'top';
            }

            let targetPos = 0;
            if (targetOffset === 'center' || $target.hasClass('offset-center')) {
              //let contentHeight = $(window).height() - $('.entry-header').height();
              targetPos = $target.offset().top + ($target.innerHeight() / 2) - ($(window).height() / 2);
              //console.log('offset center', $target.offset().top);
            } else if (targetOffset === 'bottom' || $target.hasClass('offset-bottom')) {
              targetPos = $target.offset().top + $target.outerHeight() - $(window).height();
              //console.log('offset bottom');
            } else {
              targetPos = $target.offset().top;
              //console.log('offset top');
            }
            this.scrollTo(targetPos);
          }
        }

      }
      $(window).bind("pageshow", function (event) {
        if (event.originalEvent.persisted) {
          $('#content').removeClass('transitionning');
        }
      });
    });
  }

  scrollTo(topPos) {
    if (window.smoothScrollMargin) {
      topPos -= window.smoothScrollMargin;
    }
    $('html,body').stop().animate({
      scrollTop: topPos
    }, 750);
  }

}

window.pew.addRegistryEntry({key: 'noajax-transition', domSelector: '#content', classDef: NoAjaxTransitionComponent});


import {PewComponent} from "../../../assets/raw/js/components/pew-component";

class JobMapComponentTooltip extends PewComponent {

  init() {
    this.tooltipClass = 'job-map-tooltip';
    this.tooltipWidth = 180;
    this.initListener();
  }

  initListener() {
    const id = `#${this.element.attr('id')}`;

    $(this.element).mouseenter(() => {
      const data = this.findData(id);

      if (data) {
        const $html = this.getHTMLTooltip(id, data.city, data.country, data.img, data.count);

        $('.job-map-svg-wrapper').append($html);
      }
    });

    $(`${id}`).mouseleave((e) => {
      const $newTarget = $(e.relatedTarget);
      if (!this.isTooltipHovered($newTarget)) {
        this.removeTooltip();
      }
    });

    $(document).on('mouseleave', `.${this.tooltipClass}`, (e) => {
      const $newTarget = $(e.relatedTarget);
      if (!this.isMarkerHovered($newTarget)) {
        this.removeTooltip();
      }
    });

    $(window).on('resize orientationchange', () => {
      if ($(`.${this.tooltipClass}`).length > 0) {
        this.setCssPositionToElement($(`.${this.tooltipClass}`));
      }
    });
  }

  getHTMLTooltip(id, city, country, img, count) {
    let $html = $(`<div class="${this.tooltipClass}" data-jobmap-marker-tooltip="${id}">
        <div>
          <div class="country">${country}</div>
          <div class="ville">${city}</div>
          <div class="quantite-wrapper">
            ${img}
            <span class="quantite">${count}</span>
          </div>
        </div>
    </div>`);

    $html = this.setCssPositionToElement($html);

    return $html;
  }

  setCssPositionToElement($html) {
    const parentPos = $('.job-map-svg-wrapper').offset();
    const id = $html.data('jobmap-marker-tooltip');

    const bounding = $(id).get(0).getBoundingClientRect();
    const elementWidth = bounding.width;
    let elementHeight = bounding.height;

    if ($(window).width() < 500) {
      elementHeight = elementHeight * 2;
    }

    const position = {
      top: bounding.top - parentPos.top + elementHeight,
      left: bounding.left - parentPos.left + (elementWidth / 2) - (this.tooltipWidth / 2)
    };

    $html.css({
      top: `${position.top}px`,
      left: `${position.left}px`,
      width: this.tooltipWidth,
      height: this.tooltipWidth
    });

    return $html;
  }

  removeTooltip() {
    $(`.${this.tooltipClass}`).remove();
  }

  isTooltipHovered($newTarget) {
    return $newTarget.hasClass(this.tooltipClass) || $newTarget.closest(`.${this.tooltipClass}`).length > 0;
  }

  isMarkerHovered($newTarget) {
    return $newTarget.hasClass('job-map-marker') || $newTarget.closest('.job-map-marker').length > 0;
  }

  findData(id) {
    const $accordionItem = $(`[data-jobmap-marker="${id}"]`);

    if ($accordionItem.length > 0) {
      const $panel = $accordionItem.closest('.js-accordion__panel');
      const ariaLabelBy = $panel.attr('aria-labelledby');
      const $button = $(`#${ariaLabelBy}`);

      return {
        city: $accordionItem.find('.ville').text(),
        country: $button.text(),
        img: $accordionItem.find('img').get(0) ? $accordionItem.find('img').get(0).outerHTML : '',
        count: $accordionItem.find('.quantite').text()
      };
    }
    return false;
  }
}

window.pew.addRegistryEntry({key: "job-map-tooltip", domSelector: ".job-map-marker", classDef: JobMapComponentTooltip});

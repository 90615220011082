import {gsap} from "gsap";

import {SliderCarouselComponent} from "./SliderCarouselComponent";

const mobileBreakpoint = 768;

export class SliderCarouselMobileOnlyComponent extends SliderCarouselComponent {

  init() {
    let mm = gsap.matchMedia();

    mm.add({
        // set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
        isDesktop: `(min-width: ${mobileBreakpoint}px)`,
        isMobile: `(max-width: ${mobileBreakpoint - 1}px)`,
      },
      (context) => {

        let {isDesktop, isMobile, reduceMotion} = context.conditions;

        if (isDesktop) {
          this.destroy();
        } else if (isMobile) {
          super.init();
        }
      });
  }
}

window.pew.addRegistryEntry({
  key: 'wdf-slider-carousel-mobile-only',
  domSelector: '[class$="wdf-slider-carousel-mobile-only"]',
  classDef: SliderCarouselMobileOnlyComponent
});

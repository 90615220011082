import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export default class Engagements2024 extends PewComponent {

  constructor(element, options) {
    super(element, options);
    this.setOptions({activeClass: 'is-active'});
  }

  init() {
    //this.$activeCol = this.element.find('.' + this.options.activeClass);
    this.createVideoPlayer();
    this.registerStepHighlights();
    this.registerStepClick();
  }

  createVideoPlayer() {
    this.$videoPlayer = $('<div class="engagements-video-player"></div>');
    this.element.after(this.$videoPlayer);
    const $columns = this.element.find('.engagement-item');
    //Loop through each column to loacte the .eng-video element and append it to the video player
    $columns.each((i, col) => {
      const $col = $(col),
        $video = $col.find('.eng-video');
      $video.attr('data-engagement', i);
      $col.attr('data-engagement', i);
      $video.appendTo(this.$videoPlayer);
    });
  }

  registerStepHighlights() {
    const $columns = this.element.find('.engagement-item');
    let firstLoad = true;
    $columns.hover(
      (e) => {
        const $col = $(e.currentTarget);
        if (firstLoad) {
          firstLoad = false;
          this.reduceCol(this.element.find('.engagement-item:first-child'));
        }
        this.highlightCol($col);
      },
      (e) => {
        const $col = $(e.currentTarget);
        this.reduceCol($col);
      }
    );
  }

  highlightCol($col) {
    $col.addClass(this.options.activeClass);
    const engagementId = $col.attr('data-engagement');
    if (engagementId) {
      this.highlightVideo(engagementId);
    }

  }

  reduceCol($col) {
    $col.removeClass(this.options.activeClass);
    const engagementId = $col.attr('data-engagement');
    if (engagementId) {
      this.reduceVideo(engagementId);
    }
  }

  highlightVideo(engagementId) {
    const $video = this.findVideo(engagementId);
    if($video) {
      $video.addClass(this.options.activeClass);
      $video[0].play();
    }
  }

  reduceVideo(engagementId) {
    const $video = this.findVideo(engagementId);
    if($video) {
      $video.removeClass(this.options.activeClass);
      $video[0].pause();
    }
  }

  findVideo(engagementId) {
    return $('.engagements-video-player .eng-video[data-engagement="' + engagementId + '"]');
  }

  registerStepClick() {
    const $columns = this.element.find('.engagement-item');
    $columns.on('click', (e) => {
      e.preventDefault();
      const $col = $(e.currentTarget),
        $btn = $col.find('.btn');
      console.log($col);
      /*if ($btn.length) {
        $btn.trigger('click');
      }*/
    });
  }

}

window.pew.addRegistryEntry({
  key: 'engagements2024',
  domSelector: '.engagements-list',
  classDef: Engagements2024
});

import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {tns} from "tiny-slider/dist/tiny-slider";
import {ModalComponent} from "../Modal/ModalComponent";

export default class LandingComponent extends PewComponent {

  init() {
    console.log('init');
    setTimeout(() => {
      this.registerSlider();
    }, 25);
    setTimeout(() => {
      this.registerStories();
    }, 500);
    this.registerVideos();
    /*window.addEventListener('popstate', (event) => {
      this.closeWindow();
    });
    window.onbeforeunload = ()=>{
      this.closeWindow();
    }
    window.unload = ()=>{
      this.closeWindow();
    }*/
    window.addEventListener('pageshow', (event) => {
      this.closeWindow();
      if(this.slider){
        this.slider.goTo('first');
      }
      if (event.persisted) {
        window.location.reload();
      }
    });
  }

  closeWindow() {
    console.log('closing window');
    $('body').removeClass('web-stories-lightbox-open');
    $('.web-stories-list__lightbox').removeClass('show');
  }

  registerStories() {
    const $storyLinks = this.element.find('.wp-block-tiptip-hyperlink-group-block[href^="#story"]');
    $storyLinks.each((i, card) => {
      let $card = $(card),
        storyId = parseInt($card.attr('href').replace('#story-', ''));

      let $storyTrgt = $('.web-stories-list .web-stories-list__story').eq(storyId - 1);

      if ($storyTrgt.length) {
        $card.on('click', (e) => {
          e.preventDefault();

          this.trackCardClick(storyId);

          $storyTrgt.click();
        });
      }

    });
  }

  registerVideos() {
    const $videoLinks = this.element.find('.wp-block-tiptip-hyperlink-group-block[href^="https://www.youtube.com/embed/"]');
    $videoLinks.each((i, videoCard) => {
      new ModalComponent(videoCard, {
        type: 'video',
        overlay_close: true
      });
    });
  }

  trackCardClick(storyId) {
    const EventManager = window.EventManager || $(document);
    EventManager.trigger('Tracking.customEvent', {
      category: 'story',
      action: 'card_click',
      label: storyId
    });
  }

  registerSlider() {
    // see http://ganlanyuan.github.io/tiny-slider/#options
    if (window.innerWidth < 600) {
      this.slider = tns({
        container: this.element.find('.wp-block-group__inner-container')[0],
        controlsPosition: 'bottom',
        navPosition: 'bottom',
        //edgePadding: 100,
        autoHeight: true,
        responsive: {
          600: {
            disable: true
          }
        }
      });
    }
  }
}
window.pew.addRegistryEntry({key: "landing", domSelector: ".list-grid-cta", classDef: LandingComponent});

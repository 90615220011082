import {PewComponent} from "../../../assets/raw/js/components/pew-component";

class AlerteConditionnelleComponent extends PewComponent {

  init() {
    setTimeout(() => {
      this.registerConditionReveal();
    }, 500);
  }

  registerConditionReveal() {
    const condition = parseInt(this.element.data('condition'));
    const $subjectSelector = $('.module-contact .picker-wrap select');
    $subjectSelector.on('change', (e) => {
      const formIndex = $(e.currentTarget).val();
      const $toShow = $('.contactForm:eq(' + formIndex + ')');
      const formId = parseInt($toShow.data('form'));
      this.toggle(formId === condition);
    });
  }

  toggle(display) {
    if (display) {
      this.element.slideDown();
    } else {
      this.element.slideUp();
    }
  }
}

window.pew.addRegistryEntry({key: 'alerte-conditionnelle', domSelector: '.alerte-conditionnelle-component', classDef: AlerteConditionnelleComponent});

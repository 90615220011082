import {PewComponent} from "../../../../assets/raw/js/components/pew-component";

export default class ProducteurComponent extends PewComponent {
  init() {
    this.registerProducteurHover();
    this.registerProducteurDetailToggler();
  }

  registerProducteurHover() {
    const $producteurListPart = this.element.find('.producteur-list-part');
    const $mapMarker = this.element.find('.map-marker');

    this.element.find('.map-marker,.producteur-list-part').hover(
      () => {
        $producteurListPart.addClass('hover');
        $mapMarker.addClass('hover');
      },
      () => {
        $producteurListPart.removeClass('hover');
        $mapMarker.removeClass('hover');
      }
    );
  }

  registerProducteurDetailToggler() {
    this.element.find('.map-marker,.producteur-list-part').on('click', (e) => {
      this.closeAllProducteursDetail();
      this.openProducteurDetail();
    });
    this.element.find('.producteur-detail-part .close').on('click', (e) => {
      this.closeProducteurDetail();
    });
  }

  openProducteurDetail() {
    this.element.find('.producteur-detail-part').addClass('open');
  }

  closeProducteurDetail() {
    this.element.find('.producteur-detail-part').removeClass('open');
  }

  closeAllProducteursDetail() {
    this.element.parent().find('.producteur-detail-part').removeClass('open');
  }
}

window.pew.addRegistryEntry({key: "producteur", domSelector: ".producteur-component", classDef: ProducteurComponent});
